.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-horizontal {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
}